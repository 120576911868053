
import * as Vue from 'vue';
import { useRoute } from 'vue-router';
import Data, { extractToolKey, IPage } from '@/lib/Data';
import { Prism } from '@/main';

const toolNames: { [key: string]: string } = {
  client: 'Client',
  hero: 'Hero',
  datastore: 'Datastore',
  cloud: 'Cloud',
  sql: 'SQL',
};

export default Vue.defineComponent<any>({
  async setup() {
    const route = useRoute();
    const links = Vue.ref();
    const page = Vue.ref<IPage>({ content: '', title: '', subtitles: [] });
    const toolKey = extractToolKey(route.path);
    const toolName = toolNames[toolKey];

    await Promise.all([
      Data.fetchDocLinks(route.path).then(x => (links.value = x)),
      Data.fetchDocPage(route.path).then(x => (page.value = x)),
    ]);

    return {
      links,
      page,
      content: Vue.ref<HTMLElement>(),
      toolName,
    };
  },
  mounted() {
    Prism.highlightAll();
    const pageElement = document.querySelector('.post');

    // Add a click event listener for images within the '.page' element
    pageElement?.addEventListener('click', this.handleImageClick);
  },
  beforeUnmount() {
    // Clean up the event listener to avoid memory leaks
    const pageElement = document.querySelector('.post');
    pageElement?.removeEventListener('click', this.handleImageClick);
  },
  methods: {
    isSelected(path: string) {
      return this.$route.path === path || path === `${this.$route.path}/overview/introduction`;
    },
    handleImageClick(event: any) {
      if (event.target.tagName === 'IMG') {
        if (event.target.classList.contains('fullscreen-image')) {
          event.target.classList.remove('fullscreen-image');
        } else {
          event.target.classList.add('fullscreen-image');
        }
      }
    },
  },
  computed: {
    currentPath(): any {
      return this.$route.matched[0].path;
    },

    editLink() {
      return this.items[this.currentIndex]?.editLink;
    },

    items() {
      const items: { title: string; editLink?: string; isHeader?: boolean; link: string }[] = [];
      for (const group of this.links) {
        items.push({ title: group.title, link: group.link, isHeader: true });
        for (const item of group.items) {
          items.push({ title: item.title, link: item.link, editLink: item.editLink });
          if (item.items) items.push(...item.items);
        }
      }
      return items;
    },

    currentIndex() {
      if (this.currentPath === this.rootPath) return 1;
      return (
        this.items.findIndex((item: any) => {
          return item.link.replace(/\/$/, '') === this.$route.path.replace(/\/$/, '');
        }) ?? this.items.findIndex((x: any) => !x.isHeader)
      );
    },

    nextPage() {
      for (let i = this.currentIndex + 1; i < this.items.length; i += 1) {
        const next = this.items[i];
        if (next.isHeader) continue;
        return next;
      }
      return null;
    },

    previousPage() {
      for (let i = this.currentIndex - 1; i >= 0; i -= 1) {
        const prev = this.items[i];
        if (prev.isHeader) continue;
        return prev;
      }
      return null;
    },
  },
});
